import * as api from '../api';

export function getAllSharings(organisationId) {
    return async () => {
        const sharings = await api.teal.getAllSharings(organisationId);
        return sharings;
    };
}

export function createSharing(organisationId, sharingData) {
    return async (dispatch) => {
        let sharing;
        try {
            sharing = await api.teal.createSharing(organisationId, sharingData);
        } catch(error) {
            dispatch({ type: 'ACCESS_CREATE_SHARING_FAILED', payload: { error } });
            return error;
        }

        return sharing;
    };
}

export function getSharing(organisationId, sharingId) {
    return async (dispatch) => {
        let sharing;
        try {
            sharing = await api.teal.getSharing(organisationId, sharingId);
        } catch(error) {
            dispatch({ type: 'ACCESS_GET_SHARING_FAILED', payload: { error } });
            return error;
        }

        return sharing;
    };
}

export function getSharings(organisationId, userId) {
    return async (dispatch) => {
        let sharings;
        try {
            sharings = await api.teal.getSharings(organisationId, userId);
        } catch(error) {
            dispatch({ type: 'ACCESS_GET_SHARINGS_FAILED', payload: { error } });
            return error;
        }

        return sharings;
    };
}

export function getUser(organisationId, userId) {
    return async (dispatch) => {
        let user;
        try {
            user = await api.penly.getUser(organisationId, userId);
        } catch(error) {
            dispatch({ type: 'ACCESS_GET_USER_FAILED', payload: { error } });
            return error;
        }

        return user;
    };
}

export function getFiles(organisationId, userId) {
    return async (dispatch) => {
        let files;
        try {
            files = await api.penly.getFiles(organisationId, userId);
        } catch(error) {
            dispatch({ type: 'ACCESS_GET_FILES_FAILED', payload: { error } });
            return error;
        }

        return files;
    };
}

export function uploadFile(organisationId, acceptedFiles, rejected, type, userId) {
    return async () => {
        if (rejected.length) return alert('Fejl ved upload');
        const file = acceptedFiles[0];
        const filename = file.name;
        const fileInfo = { file, filename, type };

        const uploadedFile = await api.penly.uploadFile(organisationId, userId, fileInfo);
        return uploadedFile;
    };
}
export function deleteFile(organisationId, userId, fileId) {
    return async (dispatch) => {
        let files;
        try {
            files = await api.penly.deleteFile(organisationId, userId, fileId);
        } catch(error) {
            dispatch({ type: 'ACCESS_DELETE_FILE_FAILED', payload: { error } });
            return error;
        }

        return files;
    };
}



export function getFileBinary(organisationId, userId, fileId) {
    return () => api.penly.getFileBinary(organisationId, userId, fileId);
}

export function getAccessUsers(organisationId) {
    return () => api.teal.getAccessUsers(organisationId);
}

export function getConversations(organisationId, userId) {
    return async (dispatch) => {
        let conversations;
        try {
            conversations = await api.penly.getConversations(organisationId, userId);
        } catch(error) {
            dispatch({ type: 'ACCESS_GET_CONVERSATIONS_FAILED', payload: { error } });
            return error;
        }

        return conversations;
    };
}

export function createMessage(organisationId, userId, messageData) {
    return async (dispatch) => {
        let message;
        try {
            message = await api.penly.createMessage(organisationId, userId, messageData);
        } catch(error) {
            dispatch({ type: 'ACCESS_CREATE_MESSAGE_FAILED', payload: { error } });
            return error;
        }

        return message;
    };
}
