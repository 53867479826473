import React from 'react';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import Keycloak from 'keycloak-js';

import themeSpec from 'utils/theme';
import './index.css';
import { config } from 'utils/config';
import store from 'store';
import router from 'router';
import LoadingDimmer from 'components/LoadingDimmer';
import AuthGuard from 'components/AuthGuard';
import persistor from 'utils/persistor';

import { LoadingProvider } from 'hooks/useLoading';

import './errors';

// eslint-disable-next-line no-console
console.log(`Teal Business frontend: ${config.env} build, version ${process.env.REACT_APP_VERSION}`);

const { refreshToken, idToken, token } = persistor.getTokens();

const keycloak = new Keycloak(config.keycloak);

const initOptions = {
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: `${origin}/silent-check-sso.html`,
    silentCheckSsoFallback: false,
    checkLoginIframe: false,
    refreshToken,
    idToken,
    token,
};

const root = ReactDOM.createRoot(document.getElementById('root'));

const element = (
    <LoadingProvider>
        <ReactKeycloakProvider LoadingComponent={ <LoadingDimmer /> } authClient={ keycloak } initOptions={ initOptions } onTokens={ persistor.setTokens }>
            <Provider store={ store() } >
                <ThemeProvider theme={ createTheme(themeSpec) }>
                    <AuthGuard>
                        <RouterProvider router={ router } />
                    </AuthGuard>
                </ThemeProvider>
            </Provider>
        </ReactKeycloakProvider>
    </LoadingProvider>
);

root.render(element);
