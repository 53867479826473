import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import paths from 'paths';

import { getAllSharings } from 'actions/access';
import SharingsTable from './SharingsTable';

const Orders = () => {
    const dispatch = useDispatch();
    const { organisationId } = useParams();

    const [sharings, setSharings] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetch = async () => {
            const sharings = (await dispatch(getAllSharings(organisationId))).sort((a, b) => new Date(b?.updatedAt) - new Date(a?.updatedAt));
            setSharings(sharings);
            setLoading(false);
        };
        fetch();
    }, [dispatch, organisationId]);

    const getSharingPath = (sharingId) => paths.penlyAccessSharing.getPath(organisationId, sharingId);

    return (
        <>
            { loading && (
                <div className="App-section-loading">
                    <CircularProgress  color="primary" size={ 40 } />
                </div>
            ) }
            { !loading && (
                <SharingsTable sharings={ sharings } getSharingPath={ getSharingPath } />
            ) }
        </>
    );
};

export default Orders;
