import React, { useEffect } from 'react';
import propTypes from 'prop-types';

import { useSelector, useDispatch } from 'react-redux';
import useAuthentication from 'utils/useAuthentication';

import Login from 'pages/Login';
import LoadingDimmer from 'components/LoadingDimmer';

import { getUser } from 'actions/auth';

const AuthGuard = ({ children }) => {
    const { authenticated } = useAuthentication();
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user);

    useEffect(() => {
        if (authenticated) dispatch(getUser());
    }, [authenticated, dispatch]);

    if (!authenticated) return <Login />;
    if (authenticated && !user) return <LoadingDimmer />;

    return <>{ children }</>;
};

AuthGuard.defaultProps = {};

AuthGuard.propTypes = { children: propTypes.node.isRequired };

export default AuthGuard;
