import React from 'react';
import Content from 'components/Content';
import Section from 'components/Section';

import config from 'utils/config';
import { useSelector } from 'react-redux';

const accountUrl = `${config.keycloak.url}/realms/${config.keycloak.realm}/account`;

const Account = () => {
    const user = useSelector(state => state.auth.user);

    return (
        <Content>
            <Section title='Min konto' >
                <h2>{ user.fullName }</h2>
                <h3>Email: { user.email }</h3>
                <h3>BrugerID: { user.userId }</h3>
                <p>Rediger din konto <a href={ accountUrl }>her</a>.</p>
                <p><a href="/terms-1-1.html">Forretningsbetingelser</a></p>
            </Section>
        </Content>
    );
};

export default Account;
