import { teal } from 'api';

export function createScrape(pdf) {
    return async () => {
        let scrape;
        try {
            scrape = await teal.createScrape({ pdf });
        } catch(error) {
            return error;
        }

        return scrape;
    };
    // return { type: 'SCRAPES_REQUEST_CREATE', payload: { pdf } };
}
export function convertContentToScrape(content, fileId, penlyUserId) {
    return async () => {
        let scrape;
        try {
            scrape = await teal.convertContentToScrape({ content, fileId, penlyUserId });
        } catch(error) {
            return error;
        }

        return scrape;
    };
    // return { type: 'SCRAPES_REQUEST_CREATE', payload: { pdf } };
}

export function getAllScrapes() {
    return async () => {
        let scrapes;
        try {
            scrapes = await teal.getAllScrapes();
        } catch(error) {
            return error;
        }

        return scrapes;
    };
}
export function getAllScrapesByPenlyUserId(organisationId, penlyUserId) {
    return async () => {
        let scrapes;
        try {
            scrapes = await teal.getAllScrapesByPenlyUserId(organisationId, penlyUserId);
        } catch(error) {
            return error;
        }

        return scrapes;
    };
}

export function getScrape(scrapeId) {
    return async () => {
        let scrape;
        try {
            scrape = await teal.getScrape({ scrapeId });
        } catch(error) {
            return error;
        }
        return scrape;
    };

    // return { type: 'SCRAPES_REQUEST_GET', payload: { scrapeId } };
}

export function deleteScrape(scrapeId) {
    return async () => {
        try {
            await teal.deleteScrape({ scrapeId });
        } catch(error) {
            return error;
        }
    };

    // return { type: 'SCRAPES_REQUEST_DELETE', payload: { scrapeId } };
}
