import React, { useState } from 'react';
import propTypes from 'prop-types';
import { renderKr } from 'utils/formatter';
import Button from '@mui/material/Button';
import { showFile, calculateAge, renderDate } from 'utils/helpers';

import { penly } from 'api';
import { CardContent, CardActions, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { GridContainer, GridCard } from 'components/mui/Grid';
import Clipboard from 'react-clipboard.js';
import { getTextCollectionText } from 'utils/texts';
import {
    YesNoDontKnowEnum,
}  from 'utils/enums';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useParams } from 'react-router-dom';

const relation = [
    { id: 'married', text: 'Gift' }, { id: 'cohabiting', text: 'Samlever' }, { id: 'other', text:'Anden relation' },
    { id: 0, text: 'Ukendt' }, { id: 1, text: 'Gift' }, { id: 2, text: 'Single' }, { id: 3, text: 'Fraskilt' }, // deprecated
];

const ownership = [{ id: 'personal', text: 'Personligt' }, { id: 'partner', text: 'Partners' }, { id: 'both', text: 'Begge' }];

const propertiesMap = [
    { id: 1, text: 'Ejerbolig' },
    { id: 2, text: 'Andelsbolig' },
    { id: 6, text: 'Forældrekøbsbolig' },
    { id: 5, text: 'Sommerhus' },
    { id: 3, text: 'Lejebolig' },
    { id: 7, text: 'Erhvervsejendom' },
    { id: 8, text: 'Ubebygget grund' },
    { id: 9, text: 'Bolig i udlandet' },
    { id: 4, text: 'Andet' },
];

const savingsMap = [
    { id: 1, text: 'Bankkonto' },
    { id: 2, text: 'Investering' },
    { id: 3, text: 'Børneopsparing' },
    { id: 4, text: 'Anden opsparing' },
    { id: 5, text: 'Formue i udlandet' },
    { id: 6, text: 'Aktiesparekonto (ASK)' },
];

const valuablesMap = [
    { id: 'vehicle', text: 'Køretøj' },
    { id: 'company', text: 'Virksomhed' },
    { id: 'boat', text: 'Båd' },
    { id: 'caravan', text: 'Campingvogn' },
    { id: 'other', text: 'Andet' },
];

const debtsMap = [
    { id: 7, text: 'Boliggæld' },
    { id: 1, text: 'Forbrugslån' },
    { id: 2, text: 'Studielån' },
    { id: 3, text: 'Billån' },
    { id: 4, text: 'Personligt lån' },
    { id: 5, text: 'Kassekredit' },
    { id: 8, text: 'Boliglån i bank' },
    { id: 9, text: 'Realkreditlån' },
    { id: 6, text: 'Anden gæld' },
];

const filesMap = [
    { id: 'BetalingsServiceFile', text: 'Betalingservice' },
    { id: 'BudgetFile', text: 'Budget' },
    { id: 'DepositOverviewFile', text: 'Depotoversigt fra bank' },
    { id: 'PaycheckFile', text: 'Lønseddel' },
    { id: 'PensionsInfoFile', text: 'PensionsInfo' },
    { id: 'PartnerPensionsInfoFile', text: 'PensionsInfo fra partner' },
    { id: 'ExpensesOverviewFile', text: 'Posteringsoversigt fra bank' },
    { id: 'BankYearlyOverviewFile', text: 'Årsoversigt fra bank' },
    { id: 'MortgageYearlyOverviewFile', text: 'Årsoversigt fra realkreditinstitut' },
    { id: 'TaxYearlyOverviewFile', text: 'Årsopgørelse fra SKAT' },
    { id: 'TestamentFile', text: 'Testamente' },
    { id: 'PrenuptialAgreementFile', text: 'Ægtepagt' },
    { id: 'FuturePowerOfAttorneyFile', text: 'Fremtidsfuldmagt' },
    { id: 'OtherFile', text: 'Andet' },
];

function blobToBase64(blob) {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
}

const User = ({ user }) => {
    const { organisationId } = useParams();
    const { data } = user;
    const [invitationLink, setInvitationLink] = useState();
    const { basic, household, properties, savings, valuables, debts, files } = data;
    const partnerHasSharedPensionsInfo = files?.some(x => x.fileType === 'PartnerPensionsInfoFile');
    const hasSharedPensionsInfo = files?.some(x => x.fileType === 'PensionsInfoFile');

    const handleDownloadFile = async (fileId, fileName) => {
        const content = await penly.getFileBinary(organisationId, user.userId, fileId);
        if (content) showFile(content, fileName);
    };

    const openInNewTab = async (fileId, fileName) => {
        const content = await penly.getFileBinary(organisationId, user.userId, fileId);
        const base64 = await blobToBase64(content);
        const pdfWindow = window.open('');
        pdfWindow.document.write(`<iframe width='100%' height='100%' src='${base64}'></iframe>`);
        pdfWindow.document.title = fileName;
        setTimeout(() => pdfWindow.stop(), 1000);
    };

    const invitePartnerPensionsInfo= async () => {
        const redirectUrl = `https://penly.dk/partner-uniify-flow-complete?userId=${user.userId}`;
        let link;
        try {
            link = await penly.invitePartnerToKreditdata(organisationId, user.userId, { redirectUrl, partnerEmail: household.partner.email });
        } catch (error) {
            if(error.response.status === 429){
                alert('Der er allerede sendt invitation på denne email. Har kunden ikke modtaget en email kan det skyldes at den er blokeret eller de har skrevet forkert email ind');
            } else {
                alert('der skete en fejl');
            }

        }
        setInvitationLink(link);
    };

    const birthDate = basic?.birthDate || data.pension?.analysis?.keyFigures?.birthDay;
    const incomePerMonthBeforeTax = basic?.incomePerMonthBeforeTax;
    const partnerInviteHistory = user?.integrations?.kreditdata_partner?.history ?? [];
    return (
        <div key={ user.userId }>
            { basic && (
                <div>
                    <Typography variant='h4' gutterBottom>Basisinformation</Typography>
                    <GridContainer>
                        <GridCard>
                            <CardContent style={{ paddingBottom: 8 }}>
                                <div style={{ display: 'flex' }}>
                                    { basic.name && (<Typography variant='h5' gutterBottom>{ basic.name.firstName } { basic.name.lastName }</Typography>) }
                                    { hasSharedPensionsInfo && <PictureAsPdfIcon color='primary' style={{ marginLeft: 4, position:'relative', top:-2, fontSize:30 }}/> }
                                </div>
                                { basic.cprNumber && (<p><strong>CPR-nummer:</strong> { basic.cprNumber }</p>) }
                                { birthDate && (<p><strong>Fødselsdag:</strong> { `${renderDate(birthDate, false)} (${calculateAge(birthDate)} år)` }</p>) }
                                { incomePerMonthBeforeTax && (<p><strong>Indkomst:</strong> { renderKr(incomePerMonthBeforeTax) }</p>) }

                                { basic.residence && (
                                    <p>
                                        <strong>Bopælsadresse: </strong>
                                        { basic.residence.address }, { basic.residence.zipCode } { basic.residence.city }
                                    </p>
                                ) }
                            </CardContent>
                        </GridCard>
                    </GridContainer>
                </div>
            ) }
            { household && (
                <div style={{ marginTop: 50 }}>
                    <Typography variant='h4' gutterBottom>Husstand</Typography>
                    <GridContainer>
                        <>
                            { household.partner && (
                                <GridCard>
                                    <CardContent style={{ paddingBottom: 8 }}>
                                        <div style={{ display: 'flex' }}>
                                            <Typography variant='h5' sx={{ mb: 1.5 }}>Partner: { household.partner.name.firstName } { household.partner.name.lastName }</Typography>
                                            { partnerHasSharedPensionsInfo && <PictureAsPdfIcon color='primary' style={{ marginLeft: 4, position:'relative', top:-2, fontSize:30 }}/> }
                                        </div>
                                        { household.partner.email && (<p><strong>E-mailadresse:</strong> { household.partner.email }</p>) }
                                        { household.partner.cprNumber && (<p><strong>CPR-nummer:</strong> { household.partner.cprNumber }</p>) }
                                        { household.partner.incomePerMonthBeforeTax && (<p><strong>Indkomst:</strong> { renderKr(household.partner.incomePerMonthBeforeTax) }</p>) }
                                        { household.partner.residence && (
                                            <p>
                                                <strong>Bopælsadresse: </strong>
                                                { household.partner.residence.address }, { household.partner.residence.zipCode } { household.partner.residence.city }
                                            </p>
                                        ) }
                                        { household.partner.relation && (
                                            <p><strong>{ relation.find(r => r.id === household.partner.relation.type).text } siden { household.partner.relation.since }</strong></p>
                                        ) }
                                        { partnerInviteHistory.length > 0 && <p><strong>Invitationer fra bruger:</strong> { partnerInviteHistory.map(x => x.email).join(',') }</p> }
                                        { household.partner.pensionsInfo?.invitationLink &&
                                        <>
                                            <Typography variant='h6'>Pensionsinfo - inviteret med link:</Typography>
                                            { household.partner.pensionsInfo.email && household.partner.email !== household.partner.pensionsInfo.email &&   (<p style={{ color:'red' }}><strong>E-mailadresse:</strong> { household.partner.pensionsInfo.email }</p>) }
                                            { household.partner.pensionsInfo.date && (<p><strong>Dato:</strong> { renderDate(household.partner.pensionsInfo.date) }</p>) }
                                            <Clipboard style={{ display: 'flex' }} component='div' data-clipboard-text={ household.partner.pensionsInfo.invitationLink  }>
                                                <TextField
                                                    variant="outlined"
                                                    value={ household.partner.pensionsInfo.invitationLink  }
                                                    sx={{
                                                        '& .MuiInputBase-input': {
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            background: 'white',
                                                        },
                                                        minWidth: 400,
                                                    }}
                                                    disabled
                                                />
                                                <Button
                                                    variant="contained"
                                                    endIcon={ <ContentCopyIcon /> }
                                                    sx={{ ml: 1 }}
                                                >
                                                    Copy
                                                </Button>
                                            </Clipboard>
                                        </>

                                        }
                                        {
                                            // eslint-disable-next-line
                                            !household.partner.pensionsInfo?.invitationLink || household.partner.email !== household.partner.pensionsInfo.email &&
                                            <>
                                                <p>Inviter partner til at dele pensionsinfo</p>
                                                { !invitationLink &&
                                                <Button
                                                    variant="contained"
                                                    onClick={ invitePartnerPensionsInfo }
                                                >
                                                    Inviter nu
                                                </Button>
                                                }

                                                { invitationLink &&
                                                <>
                                                    <Typography variant='h6'>Pensionsinfo - inviteret med link:</Typography>
                                                    <Clipboard style={{ display: 'flex' }} component='div' data-clipboard-text={ invitationLink  }>
                                                        <TextField
                                                            variant="outlined"
                                                            value={ invitationLink  }
                                                            sx={{
                                                                '& .MuiInputBase-input': {
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    background: 'white',
                                                                },
                                                                minWidth: 400,
                                                            }}
                                                            disabled
                                                        />
                                                        <Button
                                                            variant="contained"
                                                            endIcon={ <ContentCopyIcon /> }
                                                            sx={{ ml: 1 }}
                                                        >
                                                            Copy
                                                        </Button>
                                                    </Clipboard>
                                                </>
                                                }
                                            </>
                                        }
                                    </CardContent>

                                </GridCard>
                            ) }
                            { household.children && household.children.map((child, index) => (
                                <GridCard key={ index }>
                                    <CardContent style={{ paddingBottom: 8 }}>
                                        <Typography variant='h5' sx={{ mb: 1.5 }}>Barn: { child.name.firstName } { child.name.lastName }</Typography>
                                        { child.cprNumber && (<p><strong>CPR-nummer:</strong> { child.cprNumber }</p>) }
                                        { child.custody && (<p><strong>Forældrestatus:</strong> { ownership.find(o => o.id === child.custody).text }</p>) }
                                    </CardContent>
                                </GridCard>
                            )) }
                        </>
                    </GridContainer>
                </div>
            ) }
            { properties && (
                <div style={{ marginTop: 50 }}>
                    <Typography variant='h4' gutterBottom>Bolig og ejendom ({ properties.properties.length || 0 })</Typography>
                    <GridContainer>
                        { properties.properties && properties.properties?.map((property, index) => (
                            <GridCard key={ index }>
                                <CardContent style={{ paddingBottom: 8 }}>
                                    <Typography variant='h5' sx={{ mb: 1.5 }}>{ propertiesMap.find(p => p.id === property.type).text }</Typography>
                                    { property.value && (<p><strong>Estimeret værdi:</strong> { renderKr(property.value) }</p>) }
                                    { property.address && (
                                        <p>
                                            <strong>Adresse: </strong>
                                            { property.address.address }, { property.address.zipCode } { property.address.city }
                                        </p>
                                    ) }
                                    { property.ownership && (<p><strong>Ejerskab:</strong> { ownership.find(o => o.id === property.ownership).text }</p>) }
                                </CardContent>
                            </GridCard>
                        )) }
                    </GridContainer>
                </div>
            ) }
            { savings && (
                <div style={{ marginTop: 50 }}>
                    <Typography variant='h4' gutterBottom>Opsparing og frie midler ({ savings.savings.length || 0 })</Typography>
                    <GridContainer>
                        { savings.savings && savings.savings?.map((saving, index) => (
                            <GridCard key={ index }>
                                <CardContent style={{ paddingBottom: 8 }}>
                                    <Typography variant='h5' sx={{ mb: 1.5 }}>{ savingsMap.find(p => p.id === saving.type).text }</Typography>
                                    { saving.name && (<p><strong>Navn:</strong> { saving.name }</p>) }
                                    { saving.value && (<p><strong>Værdi:</strong> { renderKr(saving.value) }</p>) }
                                    { saving.ownership && (<p><strong>Ejerskab:</strong> { ownership.find(o => o.id === saving.ownership).text }</p>) }
                                </CardContent>
                            </GridCard>
                        )) }
                    </GridContainer>
                </div>
            ) }
            { valuables && (
                <div style={{ marginTop: 50 }}>
                    <Typography variant='h4' gutterBottom>Formuer og værdier ({ valuables.valuables.length || 0 })</Typography>
                    <GridContainer>
                        { valuables.valuables && valuables.valuables?.map((valuable, index) => (
                            <GridCard key={ index }>
                                <CardContent style={{ paddingBottom: 8 }}>
                                    <Typography variant='h5' sx={{ mb: 1.5 }}>{ valuablesMap.find(p => p.id === valuable.type).text }</Typography>
                                    { valuable.name && (<p><strong>Navn:</strong> { valuable.name }</p>) }
                                    { valuable.value && (<p><strong>Værdi:</strong> { renderKr(valuable.value) }</p>) }
                                    { valuable.ownership && (<p><strong>Ejerskab:</strong> { ownership.find(o => o.id === valuable.ownership).text }</p>) }
                                </CardContent>
                            </GridCard>
                        )) }
                    </GridContainer>
                </div>
            ) }

            { debts && (
                <div style={{ marginTop: 50 }}>
                    <Typography variant='h4' gutterBottom>Gæld ({ debts.debts.length || 0 })</Typography>
                    <GridContainer>
                        { debts.debts && debts.debts?.map((debt, index) => (
                            <GridCard key={ index }>
                                <CardContent style={{ paddingBottom: 8 }}>
                                    <Typography variant='h5' sx={{ mb: 1.5 }} color="text.secondary">{ debtsMap.find(p => p.id === debt.type).text }</Typography>
                                    { debt.name && (<p><strong>Navn:</strong> { debt.name }</p>) }
                                    { debt.value && (<p><strong>Værdi:</strong> { renderKr(debt.value) }</p>) }
                                    { debt.ownership && (<p><strong>Ejerskab:</strong> { ownership.find(o => o.id === debt.ownership).text }</p>) }
                                    { debt.principal && (<p><strong>Hovedstol:</strong> { debt.principal }</p>) }
                                    { debt.interestRate && (<p><strong>Rente:</strong> { debt.interestRate }</p>) }
                                    { debt.contribution && (<p><strong>Bidrag:</strong> { debt.contribution }</p>) }
                                    { debt.term && (<p><strong>Løbetid:</strong> { debt.term }</p>) }
                                    { debt.payment && (<p><strong>Ydelse:</strong> { debt.payment }</p>) }
                                    { debt.paymentsPerYear && (<p><strong>Ydelser pr. år:</strong> { debt.paymentsPerYear }</p>) }
                                    { debt.code && (<p><strong>Fondskode:</strong> { debt.code }</p>) }


                                </CardContent>
                            </GridCard>
                        )) }
                    </GridContainer>
                </div>
            ) }
            <div style={{ marginTop: 50 }}>
                <Typography variant='h4' gutterBottom>Andet</Typography>
                <GridContainer>
                    { user.data.pension?.base?.depositsToEarlyRetirementPay  && (
                        <tr>
                            <th style={{ width: '200px' }}>
                                Indbetaler til efterløn
                            </th>
                            <td>
                                { getTextCollectionText(YesNoDontKnowEnum, user.data.pension.base.depositsToEarlyRetirementPay ) }
                            </td>
                        </tr>
                    ) }
                </GridContainer>
            </div>
            { files && (
                <div style={{ marginTop: 50 }}>
                    <Typography variant='h4' gutterBottom>Delte filer ({ files.length })</Typography>
                    <GridContainer>
                        { files.map((file, index) => (
                            <GridCard key={ index }>
                                <CardContent>
                                    <Typography variant='h5' sx={{ mb: 1.5 }} color="text.secondary">{ filesMap.find(p => p.id === file.fileType).text }</Typography>
                                    { file.fileName && (<Typography><strong>Navn:</strong> { file.fileName }</Typography>) }
                                </CardContent>
                                <CardActions sx={{ displayPrint: false }}>
                                    <Button onClick={ () => handleDownloadFile(file.fileId, file.fileName) }>
                                        Download
                                    </Button>
                                    <Button onClick={ () => openInNewTab(file.fileId, file.fileName) }>
                                        Åben i ny fane
                                    </Button>
                                </CardActions>
                            </GridCard>
                        )) }
                    </GridContainer>
                </div>
            ) }

        </div>
    );
};

User.propTypes = {
    user: propTypes.object.isRequired,
};

export default User;
