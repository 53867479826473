import { teal } from 'api';
import { getUser } from 'actions/auth';

export function createOrganisation(organisationData) {
    return async (dispatch) => {
        let organisation;
        try {
            organisation = await teal.createOrganisation(organisationData);
        } catch(error) {
            return error;
        }

        dispatch(getUser());
        return organisation;
    };
}
