import React from 'react';

import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';

import Layout from 'components/Layout';
import Organisations from 'pages/Organisations';
import CreateOrganisation from 'pages/CreateOrganisation';

import Dashboard from 'pages/Dashboard';
import Account from 'pages/Account';
import Organisation from 'pages/Organisation';
import Integrations from 'pages/Integrations';
import PenlyAccess from 'pages/PenlyAccess';
import PenlyAccessUser from 'pages/PenlyAccessUser';
import PenlyAccessSharing from 'pages/PenlyAccessSharing';
import PenlyAccessOrder from 'pages/PenlyAccessOrder';

import paths from 'paths';


const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={ <Layout /> }>
            <Route path={ paths.start.path } element={ <CreateOrganisation /> } />

            <Route path={ paths.organisations.path } element={ <Organisations /> } />
            <Route path={ paths.account.path.withoutOrganisation } element={ <Account /> } />

            <Route path={ paths.dashboard.path } element={ <Dashboard /> } />
            <Route path={ paths.account.path.withOrganisation } element={ <Account /> } />
            <Route path={ paths.organisation.path } element={ <Organisation /> } />

            <Route path={ paths.penlyAccess.path } element={ <PenlyAccess /> } />
            <Route path={ paths.penlyAccessUser.path } element={ <PenlyAccessUser /> } />
            <Route path={ paths.penlyAccessSharing.path } element={ <PenlyAccessSharing /> } />
            <Route path={ paths.penlyAccessOrder.path } element={ <PenlyAccessOrder /> } />

            <Route path={ paths.integrations.path } element={ <Integrations /> } />
        </Route>,
    ),
    {
        future: {
            // Normalize `useNavigation()`/`useFetcher()` `formMethod` to uppercase
            v7_normalizeFormMethod: true,
        },
    },
);

export default router;
