import React, { useEffect } from 'react';
import Content from 'components/Content';
import Section from 'components/Section';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import paths from 'paths';
import { TableHead } from '@mui/material';
import { resetAccessOrders } from 'store/slices/orders';

const Page = () => {
    const navigate = useNavigate();
    const organisations = useSelector(state => state.auth.user.organisations);
    const dispatch = useDispatch();

    // if there is no organisation, we redirect to /start to create an organisation
    useEffect(() => {
        if (organisations.length === 0) navigate(paths.start.getPath());
        if (organisations.length === 1) navigate(paths.dashboard.getPath(organisations[0].organisationId));
    }, [navigate, organisations]);

    const selectOrganisation = async (organisationId) => {
        await dispatch(resetAccessOrders());
        navigate(paths.dashboard.getPath(organisationId));
    };

    return (
        <Content>
            <Section title='Mine organisationer'>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Navn</TableCell>
                                <TableCell>CVR</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { organisations.map(organisation => (
                                <TableRow
                                    key={ organisation.organisationId }
                                    onClick={ () => selectOrganisation(organisation.organisationId) }
                                >
                                    <TableCell align="left">{ organisation.name }</TableCell>
                                    <TableCell align="left">{ organisation.vatin }</TableCell>
                                    <TableCell sx={{ p: 0 }} align="right">
                                        <Button variant="contained">Vælg</Button>
                                    </TableCell>
                                </TableRow>
                            )) }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Section>
        </Content>
    );
};

export default Page;
