export default {
    start: { path: '/start', getPath: () => '/start' },
    organisations: { path: '/', getPath: () => '/' },

    dashboard: { path: '/:organisationId', getPath: (organisationId) => `/${organisationId}` },

    account: {
        path: { withoutOrganisation: '/account', withOrganisation: '/:organisationId/account' },
        getPath: (organisationId) => organisationId ? `/${organisationId}/account` : '/account',
    },

    organisation: {
        path: '/:organisationId/organisation',
        getPath: (organisationId) => `/${organisationId}/organisation`,
    },

    // pensionsInfoScraper: { path: '/pensions-info-scraper', getPath: () => '/pensions-info-scraper' },
    // pensionsInfoScrape: { path: '/pensions-info-scraper/:scrapeId', getPath: scrapeId => `/pensions-info-scraper/${scrapeId}` },

    penlyAccess: { path: '/:organisationId/access', getPath: (organisationId) => `/${organisationId}/access`, index: 1 },
    integrations: { path: '/:organisationId/integrations', getPath: (organisationId) => `/${organisationId}/integrations`, index: 1 },
    penlyAccessUser: { path: '/:organisationId/access/users/:userId', getPath: (organisationId, userId) => `/${organisationId}/access/users/${userId}` },
    penlyAccessSharing: { path: '/:organisationId/access/sharings/:sharingId', getPath: (organisationId, sharingId) => `/${organisationId}/access/sharings/${sharingId}` },
    penlyAccessOrder: { path: '/:organisationId/access/orders/:orderId', getPath: (organisationId, orderId) => `/${organisationId}/access/orders/${orderId}` },
};
