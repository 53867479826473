import React, { useState, useEffect } from 'react';
import { Button, Modal, TextField, Card, CardContent, CardMedia, Typography, Grid, Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import Content from 'components/Content';
import Section from 'components/Section';
import { teal } from 'api';
import { useParams } from 'react-router-dom';

function IntegrationsPage() {
    const { organisationId } = useParams();
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(undefined);
    const [isLoading, setLoading] = useState(false);
    const [integrations, setIntegrations] = useState({});
    const [config, setConfig] = useState({
        authToken: '', // You can add other configuration keys here
    });
    useEffect(() => {
        const fetch = async () => {
            const response = await teal.getIntegrations(organisationId);
            response.forEach((integration) => {
                integrations[integration.integrationType] = integration;
            });
            setIntegrations({ ...integrations });
        };
        fetch();
        setLoading(false);
    }, []);
    const handleConfigChange = (key, value) => {
        setConfig({
            ...config,
            [key]: value,
        });
        setError(undefined);
    };
    const handleOpen = () => {
        setConfig(integrations.WebCRM ?? {});
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleSave = async () => {
        if (!config.authToken) {
            setError('Mangler token');
            return;
        }
        try {
            const response = await teal.updateIntegration(organisationId, 'WebCRM', config);
            setIntegrations({ ...integrations, WebCRM: response });
        } catch (error) {
            setError('Der skete en fejl');
            throw error;
        }

        handleClose();
    };
    const cardStyle = { maxWidth: 345, padding: 10, paddingBottom: 0 };
    const cardContentStyle = { display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' };
    if (isLoading) {
        return <CircularProgress color="inherit" />;
    }
    return (
        <Content>
            <Section title={ 'Integrationer' }>
                <Grid container spacing={ 2 }>
                    <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                        <Card style={ cardStyle }>
                            <CardMedia
                                component="img"
                                alt="WebCRM"
                                width="100%"
                                image="https://webcrm.com/frontend/dist/img/icons/webCRM-an-efficy-product.svg" // Replace with the actual image URL
                            />
                            <CardContent style={ cardContentStyle }>
                                <div>
                                    <Typography variant="h5" component="div">
                                        WebCRM
                                    </Typography>
                                    <p>
                                        Med denne integration kan ordre fra Penly-brugere synkroniseres med WebCRM således at deres data deles og opdateres automatisk, hvilket bidrager til en mere
                                        strømlinet arbejdsgang.
                                    </p>
                                </div>
                                <Button variant="contained" onClick={ handleOpen } style={{ alignSelf: 'flex-end', marginBottom: '-10' }}>
                                    { integrations.WebCRM ? 'Rediger' : 'Tilføj' }
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Modal open={ open } onClose={ handleClose }>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                            overflowY: 'scroll',
                            maxHeight: '95vh',
                        }}
                    >
                        <Typography variant="h4" sx={{ mb: 2 }}>
                            { integrations.WebCRM ? 'Rediger' : 'Tilføj' } WebCRM Integration
                        </Typography>
                        <TextField
                            label="Auth Token"
                            variant="outlined"
                            value={ config.authToken }
                            onChange={ (e) => handleConfigChange('authToken', e.target.value) } // Update authToken or other config
                        />
                        { error && <p style={{ color: 'red' }}>{ error }</p> }
                        <p>
                            Auth token er en nøgle, der entydigt identificerer din webCRM-instans og en række adgangsrettigheder til de forskellige dele af webCRM. Opret auth token på{ ' ' }
                            <a href="https://v5.b2bsys.net/Configuration/Integration/API/IntegrationApi.aspx?mid=1">webcrm.com &gt; Konfiguration &gt; Integration &gt; API &gt; REST</a>. (Log ind på webCRM, før
                            du bruger linket.) og sæt ind ovenover.
                        </p>
                        <Button variant="contained" onClick={ handleSave }>
                            Gem
                        </Button>
                    </Box>
                </Modal>
            </Section>
        </Content>
    );
}

export default IntegrationsPage;
