import React from 'react';
import { useSelector } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import useAuthentication from 'utils/useAuthentication';
import paths from 'paths';
import { config } from 'utils/config';

const Navigation = () => {
    const { logout } = useAuthentication();
    const location = useLocation();
    const { organisationId } = useParams();
    const user = useSelector(state => state.auth.user);
    const organisation = user.organisations.find(org => org.organisationId === organisationId);

    const pages = [];
    if (organisationId) {
        pages.push({ label: 'Penly Access', path: paths.penlyAccess.getPath(organisationId) });

        if(config.featureFlags?.integrations ){
            pages.push({ label: 'Integrationer', path: paths.integrations.getPath(organisationId) });
        }
    } else {
        pages.push({ label: 'Vælg organisation', path: paths.organisations.getPath() });
    };

    const settings = [{ label: 'Min konto', path: paths.account.getPath(organisationId) }];
    if (organisationId) {
        settings.push({ label: 'Organisation', path: paths.organisation.getPath(organisationId) });
    }

    settings.push({ label: 'Log ud', onClick: logout });

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => setAnchorElNav(event.currentTarget);
    const handleOpenUserMenu = (event) => setAnchorElUser(event.currentTarget);
    const handleCloseNavMenu = () => setAnchorElNav(null);
    const handleCloseUserMenu = () => setAnchorElUser(null);

    return (
        <AppBar position='static'>
            <Container maxWidth="lg">
                <Toolbar disableGutters>
                    <RouterLink to={ paths.organisations.getPath() }>
                        <img style={{ height: 30, paddingRight: 20 }} src="/TealBusinessLogo.svg" alt="Teal Business" />
                    </RouterLink>

                    <Box sx={{ displayPrint: 'none', flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            onClick={ handleOpenNavMenu }
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        { organisation?.ready && (
                            <Menu
                                id="menu-appbar"
                                anchorEl={ anchorElNav }
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={ Boolean(anchorElNav) }
                                onClose={ handleCloseNavMenu }
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                { pages.map((page) => (
                                    <MenuItem key={ page.label } onClick={ handleCloseNavMenu }>
                                        <Link
                                            underline="none"
                                            component={ RouterLink }
                                            to={ page.path }
                                        >
                                            <Typography textAlign="center">{ page.label }</Typography>
                                        </Link>
                                    </MenuItem>
                                )) }
                            </Menu>
                        ) }
                    </Box>

                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        { organisation?.ready && pages.map((page, index) => (
                            <Button
                                key={ page.label }
                                onClick={ handleCloseNavMenu }
                                sx={{
                                    my: 2,
                                    color: 'white',
                                    display: 'block',
                                    fontWeight: (location.pathname === page.path || (location.pathname === '/' && index === 0)) ? 'bold' : 'normal',
                                    position: 'relative', // Add position relative for pseudo-element
                                }}
                                component={ RouterLink }
                                to={ page.path }
                            >
                                { page.label }
                                { (location.pathname === page.path || (location.pathname === '/' && index === 0)) && ( // Check if it's the active button
                                    <span
                                        className="underline" // Add a class for styling the underline
                                    ></span>
                                ) }
                            </Button>
                        )) }
                    </Box>

                    <Box sx={{ displayPrint: 'none', flexGrow: 0 }}>
                        <IconButton onClick={ handleOpenUserMenu } sx={{ p: 0, color: 'white' }}>
                            { user.firstName } { organisation && `| ${organisation.name}` }
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={ anchorElUser }
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={ Boolean(anchorElUser) }
                            onClose={ handleCloseUserMenu }
                        >
                            { settings.map((setting) => (
                                <MenuItem key={ setting.label } onClick={ handleCloseUserMenu }>
                                    <Button
                                        fullWidth
                                        disableRipple
                                        disableFocusRipple
                                        component={ RouterLink }
                                        to={ setting.path }
                                        onClick={ setting.onClick }
                                    >
                                        { setting.label }
                                    </Button>
                                </MenuItem>
                            )) }
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};


export default Navigation;
