import {
    createEntityAdapter,
    createSlice,
} from '@reduxjs/toolkit';

const usersAdapter = createEntityAdapter({
    selectId: (users) => users.userId,
});

export const { getSelectors } = usersAdapter;

const usersSlice = createSlice({
    name: 'organisationUsers',
    initialState: usersAdapter.getInitialState({
        loaded: false,
        loading: false,
        error: null,
    }),
    reducers: {
        /* eslint-disable no-param-reassign */
        requestedAll(state) {
            state.loading = true;
        },
        fetchedAll(state, action) {
            state.loading = false;

            state.loaded = true;
            usersAdapter.setAll(state, action.payload.users);
        },
        failed(state, action) {
            state.loading = false;
            state.error = action.payload.error;
        },
        /* eslint-enable no-param-reassign */
    },
});

export default usersSlice;
