/* eslint-disable */
import React, { useState, useEffect } from "react"
import { Button } from "@mui/material";
import { useDispatch } from 'react-redux';
import { List, ListItem, ListItemText } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { useParams } from 'react-router-dom';
import MuiAlert from '@mui/material/Alert';
import DOMPurify from 'dompurify';
import TextEditor from "./TextEditor"
import { createMessage } from 'actions/access';
import { renderDateTime } from 'utils/helpers';
export default function PenlyAccessUserNotes({ user, penlyUser, init }) {
  const [showEditor, setShowEditor] = useState(false);

  const subject = 'Fejl i case, bruger: ' + penlyUser.firstName + ' ' + penlyUser.lastName + ', id: ' + penlyUser.userId + ', email: ' + penlyUser.email
  const developerEmails = 'jakob@penly.dk,asbjoern@penly.dk'
  const dispatch = useDispatch();
  const { organisationId } = useParams();
  const [messages, setMessages] = useState([]);
  const [snack, setSnack] = useState();
  useEffect(() => {
    setMessages(init);
  }, [init]);

  const handleSubmit = async (content) => {
    const messageData = {
      audience: 'importer',
      name: user.fullName,
      message: { content },
    };
    const messageOrError = await dispatch(createMessage(organisationId, penlyUser.userId, messageData));
    if (messageOrError.name === 'AxiosError') {
      setSnack({message: 'Besked kunne ikke sendes'})
      localStorage.setItem('PenlyAccessUserNotes', JSON.stringify(content));
    } else {
      setShowEditor(false)
      const message = {
        _id: messages.length + 1,
        content,
        sender: { userId: user.userId, name: user.fullName },
      };
      setMessages([...messages, message]);
    }
  }



  return (
    <div sx={{
      width: '100%',
      backgroundColor: 'background.paper',
    }}>
      <p>Gem noter på Penly brugeren til dig selv og andre rådgivere i din virksomhed. Brugeren har ikke adgang til noterne. Du kan også indberette fejl til udviklere via email.</p>
              <Snackbar
                open={ !!snack }
                autoHideDuration={ 6000 }
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={ () => setSnack(undefined) }
            >
                <MuiAlert severity={ snack?.severity || 'error' }>{ snack?.message }</MuiAlert>
            </Snackbar>
      <List>
        {messages.map((message) => (
          <ListItem
            key={message._id}
            sx={{
              backgroundColor: 'grey.200',
              padding: 2,
              marginBottom: 2,
              borderRadius: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              '&.selectedConversation': {
                // styles for the selected conversation
              },
              '&:hover': {
                // styles for when the item is hovered
              },
            }}
          >


            <ListItemText
              primary={message.sender.name}
              secondary={renderDateTime(message.date)}
              primaryTypographyProps={{ variant: 'h6' }}
              secondaryTypographyProps={{
                variant: 'body2',
              }}
            />
             <div
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message.content) }}
              />
          </ListItem>
        ))}
      </List>
      {
        showEditor ?
          <TextEditor emailButtonText={'Indberet til udviklere'} saveButtonText={'Send'} contentKey={'PenlyAccessUserNotes'} onSubmit={handleSubmit} onEmail={() => window.location.href = `mailto:${developerEmails}?subject=${subject}&body=Hej Jakob og Asbjørn.`}></TextEditor> :
          <Button variant='outlined' onClick={() => setShowEditor(true)} color="primary"> Ny note</Button>

      }
    </div>

  )
}
