import { configureStore } from '@reduxjs/toolkit';

import initialState from './reducers/initial';
import reducer from './reducers';

import { getSelectors as ordersSelectors } from './slices/orders';

// eslint-disable-next-line import/no-mutable-exports
export let store;

const Store = () => {
    if (store) return store;
    store = configureStore({
        reducer,
        preloadedState: { ...initialState, accessOrders: { ids: [], entities: { } }, organisationUsers: { ids: [], entities: { } } },
    });

    return store;
};

export default Store;

const orders = ordersSelectors();
export const selectAccessOrders = () => orders.selectAll(store.getState().accessOrders);
