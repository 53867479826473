import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Content from 'components/Content';
import Section from 'components/Section';

function OrganisationPage() {
    const { organisationId } = useParams();
    const organisation = useSelector(state => state.auth.user.organisations.find(org => org.organisationId === organisationId));

    return (
        <Content>
            <Section title={ 'Organisation' } >
                <Table style={{ marginTop: '20px' }}>
                    <TableBody>
                        <TableRow>
                            <TableCell>Virksomhedsnavn:</TableCell>
                            <TableCell>Virksomhedens ID:</TableCell>
                            <TableCell>CVR-nummer:</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{ organisation.name }</TableCell>
                            <TableCell><span style={{ fontFamily: 'Courier, monospace' }} >{ organisation.organisationId }</span></TableCell>
                            <TableCell>{ organisation.vatin }</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Section>
        </Content>
    );
}

export default OrganisationPage;
